import React, { Component } from "react";
import "./Home.css";

class Home extends Component {
  render() {
    return (

    <div className="main">

        <div className="launch">
            <h1>Investing in a decentralized future</h1>
        </div>

        <div className="about">
            <img src="Kindi Capital Caps Logo.svg" className="logo-caps" alt="Kindi Capital logo in capitals"/>
            <img src="Kindi Logo Gold.png" className="logo-gold" alt="Kindi Capital logo in gold"/>
            <div className="bloc1">
                <h2>Kindi Capital is an investment firm focusing on cryptographic assets, blockchains and decentralized protocols. <br></br><br></br><br></br>We are a team of crypto-native technologists, investing in cutting edge technologies that will shape every aspect of tomorrow’s social and economic interactions.</h2>
            </div>
            <div className="bloc2">
                <div>
                    <img src="silhouette-couleur.png" alt="Arabian investor men in a traditionnal outfit"/> 
                </div>
            </div>
        </div>

        <div className="contact">  
            <img src="Kindi Capital Caps Logo.svg" className="logo-caps-bis" alt="Kindi Capital logo in capitals"/>
            <img src="Kindi Logo Gold.png" className="logo-gold-bis" alt="Kindi Capital logo in gold"/>    
            <div className="img-container">
                <h1>Get in touch</h1>
                <p><a href="mailto:investors@kindi.capital">investors@kindi.capital</a></p>
                <p><a href="mailto:press@kindi.capital">press@kindi.capital</a></p>
                <p><a href="mailto:hiring@kindi.capital">hiring@kindi.capital</a></p>
                <p><a href="mailto:team@kindi.capital">team@kindi.capital</a></p>
                    
            </div>
        </div>

    </div>
    );
  }
}
export default Home;
